import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import ImageCaptionSection from "../../components/imageCaptionSection";
import HelpMethodsSection from "../../components/helpMethodsSection";
import NewsPreviewCard from "./newsPreviewCard";
import Pagination from "../../components/pagination";

function NewsIndex(props) {
  const {data, pageContext: {numPages, currentPage}} = props;

  const {title, subTitle, imageCaptionBackground} = data.markdownRemark.frontmatter.imageCaptionSection;

  const newsPosts = data.allMarkdownRemark.edges.map(newsPost => ({
    ...newsPost.node.fields,
    ...newsPost.node.frontmatter,
  }));
  console.log('debug', data.allMarkdownRemark.edges);

  return (
    <Layout activeLink="/stay-informed" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>

      <div className="flex flex-row flex-wrap justify-evenly mt-20">
        {newsPosts.map((newsPost, index) => <NewsPreviewCard key={index} news={newsPost}/>)}
      </div>
      <Pagination totalPages={numPages} currentPage={currentPage} baseURL="/news/"/>

      <HelpMethodsSection/>
    </Layout>
  );
}

export default NewsIndex;

export const newsIndexQuery = graphql`
  query NewsIndexQuery($skip: Int!, $limit: Int!) {
    markdownRemark(frontmatter: {templateKey: {eq: "news"}}) {
      frontmatter {
        imageCaptionSection {
          subTitle
          title
          imageCaptionBackground
        }
      }
    }
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/pages/news/"}}
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            coverPicture
          }
        }
      }
    }
  }
`;
